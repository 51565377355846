import { useEffect, useState, useRef } from 'react'
import { useNavigate, usePathParams } from 'raviger';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import useGlobal from 'store/store';
import useApi from 'hooks/useApi';
//libs
import ReactCodeInput from 'react-verification-code-input';
import { format } from 'date-fns'
import * as yup from 'yup';
//mui
import { TextField, Button, Tooltip, Slide, Select, Autocomplete, MenuItem, InputLabel, FormControl, FormHelperText, CircularProgress, Box, Typography, ButtonGroup, Switch, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container, LinearProgress, IconButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//components
import { HBox, VBox } from 'assets/SharedComponents';
import GenericDialog from 'Components/Common/GenericDIalog';
//api
import { getSchoolsList, getTribes, updatePupilStatment, getBalanceCalc, getTroopGroup, getChildBalance, getRegAPI } from "api/api"
//data
import { permanentInfo } from 'data/permanentInfo';
//assets
import childError from '../../../assets/childError.svg'
import { CustomBusy } from 'Components/Common/CustomBusy';

const useStyles = makeStyles((theme) => ({
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none !important'
            }
        }
    },
    padding1: {
        padding: "1rem"
    },
    marginBottom1: {
        marginBottom: "0.85rem !important"
    },
    width8: {
        width: "8rem !important",
    },
    width6: {
        width: "6rem !important",
    },
    buttonGreen: {
        color: '#ffffff !important ',
        fontSize: '1rem !important',
        fontWeight: 'bold !important',
        backgroundColor: '#3a7735 !important',
        borderRadius: '2rem !important',
        width: '12rem'
    },
    selectedBtn: {
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "#ffffff !important",
        textTransform: "capitalize !important"
    },
    IdnBtn: {
        textTransform: "capitalize !important",
        backgroundColor: "#efefef  !important",
        color: "#333333 !important",
        border: "none !important",
    },
    pageStyle: {
        maxWidth: '30rem !important',
        paddingBottom: '6rem !important',
        margin: 'auto !important',
        '&.MuiCardContent-root': {
            padding: '0px !important'
        }
    },
    IdentificationBtn: {
        backgroundColor: "#efefef !important",
        borderTopLeftRadius: "1rem !important",
        borderBottomLeftRadius: "1rem !important",
        borderBottomRightRadius: "1rem !important",
        borderTopRightRadius: "1rem !important",
        width: "17rem !important",
        '& .MuiButtonGroup-groupedHorizontal': {
            borderTopLeftRadius: "1rem !important",
            borderBottomLeftRadius: "1rem !important",
            borderBottomRightRadius: "1rem !important",
            borderTopRightRadius: "1rem !important"
        }
    },
    birthdayInput: {
        alignItems: "flex-end",
        width: "max-content",
        direction: "ltr"
    },
    datePinInputStyle: {
        width: 'auto !important;',
        "& input": {
            borderBottom: '3px solid #3a7735 !important;',
            border: 'none !important',
            width: '2rem !important;',
            height: 'auto !important;',
            fontSize: '1.3rem !important;',
            textAlign: "center !important;",
            outlineWidth: "0px !important;",
            borderRadius: "0px !important;",
            fontFamily: "Alef,sans-serif !important",
            caretColor: "auto !important;",
            backgroundColor: "transparent !important",
            marginRight: '2.5px !important'
        }
    },
    switchAndName: {
        alignItems: "flex-start",
        justifyContent: "space-between"
    },
    pupilName: {
        fontSize: "1.125rem"
    },
    underLineText: {
        "&:hover": {
            textDecoration: 'underline !important',
            cursor: 'pointer !important'
        },
        textDecoration: 'underline !important',
        fontSize: '0.8 !important',
        color: '#007f00 !important',
        textAlign: "left"
    },
    dialog: {
        borderRadius: '25px !important'
    },
    dialogTitle: {
        fontWeight: "bold !important",
        fontSize: "1.2rem !important",
        marginRight: "0.7rem  !important"
    },
    dialogTitleAndIcon: {
        justifyContent: 'space-between !important',
        alignItems: 'center !important',
    },
    dialogText: {
        fontSize: "1rem !important",
        color: "black !important",
        textAlign: "center !important"
    },
    dialogAction: {
        justifyContent: "center !important"
    },
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    },
    width75: {
        width: "75%"
    }
}));

export const ChildDetailsEditor = ({ setIsUpdated, isUpdated, fromTribe = false, fromDrawer = false, setFamilyUpdated, familyUpdated, setAppBarTitle }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const schools = useApi(getSchoolsList);
    const tribeOption = useApi(getTribes);
    const updateStatmentApi = useApi(updatePupilStatment);
    const troopGroup = useApi(getTroopGroup);
    const navigate = useNavigate();
    const [tribesList, setTribesList] = useState([]);
    const [troopList, setTroopList] = useState([]);
    const [schoolsList, setSchoolList] = useState([]);
    const [schoolValue, setSchoolValue] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [disableToChange, setDisableToChange] = useState(true);
    const [birthdayError, setBirthdayError] = useState(false);
    const [childReqText, setChildReqText] = useState(false);

    const inputRef = useRef();
    const inputRef1 = useRef();
    const dayRef = useRef();
    const monthRef = useRef();
    const yearRef = useRef();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [household] = useGlobal((state) => state.household);
    const [family, setFamily] = useGlobal((state) => state.family, (actions) => actions.setFamily);
    const [child, setChild] = useGlobal(state => state.child, actions => actions.setChild);
    const [products, [removeHouseholdSelection]] = useGlobal((state) => state.products, (actions) => [actions.removeHouseholdSelection]);

    const props = usePathParams('/childDetailsEditor/:familyCardCode');
    const familyCardCode = props?.familyCardCode;
    const [birthday, setBirthday] = useState(child?.BirthdayDate ? { year: format(new Date(child?.BirthdayDate), "yyyy"), month: format(new Date(child?.BirthdayDate), "MM"), day: format(new Date(child?.BirthdayDate), "dd") } : { year: "2000", month: "01", day: "01" });

    useEffect(() => {
        if (familyCardCode && !child) {
            navigate(`/tribeMyFamily/${familyCardCode}`);
            return
        }
        if (!child && !fromTribe) {
            navigate(`/MyFamily`);
            setAppBarTitle(t("myFamily"));
            return
        }
    }, [])

    useEffect(async () => {
        let isMounted = true;
        try {
            if (child) {
                const school = await schools.requestPromise();
                isMounted && setSchoolList(school.d.results);
                const res = await tribeOption.requestPromise();
                isMounted && setTribesList(res.d.results);
                const query = { childClass: child?.Class, childTribe: child?.DflWhs };
                const troopResult = await troopGroup.requestPromise(query);
                isMounted && setTroopList(troopResult);
            }
        } catch (error) {
            console.log(error);
        }
        return () => { isMounted = false; }
    }, [])

    const valueChanged = (value) => {
        formik.setValues({ ...formik.values, school: value.SchoolCode });
    }
    const isValidYear = (value) => {
        const currentYear = new Date().getFullYear();
        const limitMinYear = currentYear - 19;
        const limitMaxYear = currentYear - 7;
        if (value >= limitMinYear && value <= limitMaxYear) {
            setBirthday({ ...birthday, year: value });
            formik.setValues({ ...formik.values, year: value });
            setBirthdayError(false);
        } else
            setBirthdayError(true);
    }
    const isValidMonth = (value) => {
        if (value >= 1 && value <= 12) {
            setBirthday({ ...birthday, month: value });
            formik.setValues({ ...formik.values, month: value });
            setBirthdayError(false);
            yearRef.current.iRefs[0].current.focus();
        } else
            setBirthdayError(true);
    }
    const isValidDay = (value) => {

        if (value >= 1 && value <= 31) {
            setBirthday({ ...birthday, day: value });
            formik.setValues({ ...formik.values, day: value });
            setBirthdayError(false);
            monthRef.current.iRefs[0].current.focus();
        } else
            setBirthdayError(true);
    }
    const onBirthdayDayChange = day => {

        setBirthday({ ...birthday, day });
    }
    const onBirthdayMonthChange = month => {

        setBirthday({ ...birthday, month });
        if (month === "") {
            dayRef.current.iRefs[1].current.focus();
        }
    }
    const onBirthdayYearChange = year => {

        setBirthday({ ...birthday, year });
        if (year === "") {
            monthRef.current.iRefs[1].current.focus();
        }
    }

    //Zofim request to check if birthday year is valid about 3 years difference to child current class (not on Zamid pupils)
    const rangeValidation = (year, childClass) => {
        const classOptions = [];
        const currentYear = new Date().getFullYear();
        const range = currentYear - year;
        const classSapNumber = Object.keys(permanentInfo.childClasses).find(key => permanentInfo.childClasses[key] === childClass);
        Object.keys(permanentInfo.childClasses).forEach((c) => {
            if (range - c >= 6 && range - c <= 9) { classOptions.push(c); }

        })
        if (classOptions.includes(classSapNumber)) { return true; }
        else {
            return false;
        }

    }
    const validationSchema = yup.object({
        firstName: yup
            .string(t("firstName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectName')),
        lastName: yup
            .string(t("lastName"))
            .required(t("mandatoryField"))
            .min(2, t('insertCorrectLName'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t('insertCorrectLName')),
        tribe: yup
            .string(t("tribe"))
            .required(t("mandatoryField")),
        school: yup
            .string()
            .nullable()
            .required(t("mandatoryField"))
            .test('not-null', t("mandatoryField"), value => value !== null),
        gender: yup
            .string(t("gender"))
            .required(t("mandatoryField")),
        id: yup
            .string(t("id"))
            .required(t("mandatoryField"))
            .min(8, t('enterValidValue'))
            .max(30, t('enterValidValue')),
        childPhone: yup
            .string(t("phoneNumber"))
            .min(9, t("insertValidPhone"))
            .max(10, t("insertValidPhone"))
            .matches(/^[0-9]*$/, t('insertValidPhone'))
            .nullable(),
        class: yup
            .string(t("class"))
            .required(t("mandatoryField")),
        genderAppeal: yup
            .string(t("genderAppeal"))
            .max(30, t('enterValidValue'))
            .matches(/^[a-z\s\u0590-\u05fe]+$/i, t("insertRightText"))
            .nullable(),
        troop: yup
            .string(t("troop"))
            .nullable()
    });
    const formik = useFormik({
        initialValues: {
            isActive: child?.PupilStatus === "N" || child?.PupilStatus === null ? false : true,
            firstName: child?.Pupil_FirstName,
            lastName: child?.Pupil_lastName,
            tribe: child?.DflWhs,
            school: child?.School,
            genderAppeal: child?.Pronounce,
            birthday: child?.BirthdayDate,
            gender: permanentInfo.childGender[child?.Gender],
            idType: child?.ID_TYPE ? child?.ID_TYPE : "1",
            id: child?.LicTradNum,
            childPhone: child?.Phone1 ? child?.Phone1.slice(-10) : "",
            troop: `${child?.Troops || ""},${child?.Group || ""}`,
            class: permanentInfo.childClasses[child?.Class],
            year: birthday.year,
            month: birthday.month,
            day: birthday.day
        },
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!rangeValidation(values.year, values.class)) {
                const key = enqueueSnackbar(t("classToAgeError"), { className: classes.redBG });
                setTimeout(() => { closeSnackbar(key) }, 1300);
                return
            }
            if (!is_israeli_id_number(values.id) && values.idType === "1") {
                enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                return
            }
            if (values.id.toString().length < 9) {
                if (values.idType === "3") {
                    enqueueSnackbar(t("wrongJId"), { className: classes.redBG });
                    return
                }
                if (values.idType === "1") {
                    enqueueSnackbar(t("wrongId"), { className: classes.redBG });
                    return
                }
            }
            if (permanentInfo.childClasses[child?.Class] !== values.class) {
                const childRegReq = await getRegAPI({ StudentCardCode: child?.CardCode, Status_request: '2' });
                if (childRegReq.data?.length) {
                    setDialogOpen(true);
                    setChildReqText(true);
                    return
                }
            }
            if (!birthdayError) {
                const data = {
                    "Source": fromTribe ? "ASH" : "APH",
                    "CardCode": child?.CardCode,
                    "TribeNum": values.tribe,
                    "Pupil_LastName": values.lastName,
                    "Pupil_FirstName": values.firstName,
                    "Pupil_ID": values.id.toString(),
                    "School": values.school,
                    "Pupil_BirthdayDate": `${values.year}-${values.month}-${values.day}`,
                    "Pupil_Gender": Object.keys(permanentInfo.childGender).find(key => permanentInfo.childGender[key] === values.gender),
                    "Pupil_Kupa": child?.Kupa,
                    "Pupil_Class": Object.keys(permanentInfo.childClasses).find(key => permanentInfo.childClasses[key] === values.class),
                    "Pupil_FatherCard": fromTribe ? family.CardCode : household.CardCode,
                    "Pupil_PlaceOfBirth": child?.Pupil_BirthState,
                    "Pupil_EmigrationDate": child?.EmigrDate,
                    "PermPartWater": child?.PermPartWater,
                    "Zamid": "N",
                    "PerPhotAndPubl": child?.PerPhotAndPubl,
                    "LiveWithParent2": child?.LiveWithParent2,
                    "LiveDifAdress": child?.LiveDifAdress,
                    "LiveOutsideIsrael": "N",
                    "EmerDoctor": child?.EmerDoctor,
                    "City": child?.city,
                    "Street": child?.street,
                    "Country": child?.Country,
                    "StreetNo": child?.StreetNo,
                    "ZipCode": child?.ZipCode,
                    "OtherAllergyName": child?.OtherAllergyName,
                    "OtherDiseaseName": child?.OtherDiseaseName,
                    "OtherFoodName": child?.OtherFoodName,
                    "PermPart": child?.PermPart,
                    "Troops": values.troop.split(',')[0] || "",
                    "Group": values.troop.split(',')[1] || "",
                    "ID_Type": values.idType,
                    "Tel1Type": "1",
                    "Tel2Type": "1",
                    "Phone1": values.childPhone,
                    "Phone2": "",
                    "PupilStatus": values.isActive ? "Y" : "N",
                    "Pronounce": values.genderAppeal,
                    "Pupil_Diseases": child?.DiseasesList.results,
                    "Pupil_Allergies": child?.AllergiesList.results,
                    "Pupil_FoodPreferences": child?.FoodPreferencesList.results
                }
                let updateResult = await updateStatmentApi.requestPromise(data);
                if (updateResult.Status) {
                    if (values.tribe !== child?.DflWhs) {
                        const existsInCart = products.filter((el) => el.StudentCardCode === child?.CardCode);
                        if (existsInCart.length)
                            for (let i = 0; i < existsInCart.length; i++) {
                                removeHouseholdSelection(existsInCart[i]);
                            }
                    }
                    child.School = values.school;
                    enqueueSnackbar(`${t("studentDetailsUpdate")} ${child?.Pupil_FirstName} ${t("updateSuccssefuly")}`, { className: classes.orangeBG });
                    if (fromTribe) {
                        setFamilyUpdated(familyUpdated + 1);
                        if (!fromDrawer) {
                            navigate(`/tribeMyFamily/${family.CardCode}`);
                        }
                    }
                    else {
                        setIsUpdated(isUpdated + 1);
                        setAppBarTitle(t("myFamily"));
                        navigate('/MyFamily');
                    }
                }
                else {
                    enqueueSnackbar(`${t("updateStudentError")} ${child?.Pupil_FirstName} `, { className: classes.redBG });
                }
            }
        }
    });

    const getTribeLabel = (option) => {
        if (option.Street)
            return (`${option.Name} , ${option.Street} ${option.City}`);
        else
            return (`${option.Name} ,  ${option.City}`);
    }

    const is_israeli_id_number = (id) => {
        id = String(id).trim();
        if (id.length > 9 || isNaN(id)) return false;
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
        return Array.from(id, Number).reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
    }

    const changeTribe = async () => {
        try {
            const data = await getBalanceCalc(child?.FatherCard);
            const childData = await getChildBalance({ childCardCode: child?.CardCode });
            const { HasTransactions } = childData.d?.results[0];
            const childRegReq = await getRegAPI({ StudentCardCode: child?.CardCode, Status_request: '2' });
            if (childRegReq.data?.length) {
                setDialogOpen(true);
                setChildReqText(true);
                return;
            }
            const pupilTribe = data.d?.results?.filter(tribe => tribe.TribeCode === child?.DflWhs) || [];
            if (pupilTribe.length) {
                const parentBalance = pupilTribe[0].ParentBalance;
                if (HasTransactions === "N") {
                    setDisableToChange(false);
                }
                // if ((parentBalance === null || parentBalance === "0") && HasTransactions === "N") {
                //     setDisableToChange(false);
                // }
                else {
                    setDialogOpen(true);
                }
            }
            else if (HasTransactions === "N") {
                setDisableToChange(false);
            }
        } catch (error) {
            console.log(error);
        }

    }
    // const changeTribe = async () => {
    //     const data = await getBalanceCalc(child?.FatherCard);
    //     const pupilTribe = data.d?.results?.filter(tribe => tribe.TribeCode === child?.DflWhs) || [];
    //     if (pupilTribe.length) {
    //         const parentBalance = pupilTribe[0].ParentBalance;
    //         if (parentBalance === null) {
    //             setDisableToChange(false);
    //         }
    //         else {
    //             setDialogOpen(true);
    //         }
    //     }
    //     else {
    //         setDisableToChange(false);
    //     }
    // }
    const classBlur = () => {
        if (!rangeValidation(formik.values.year, formik.values.class)) {
            const key = enqueueSnackbar(t("classToAgeError"), { className: classes.redBG });
            setTimeout(() => { closeSnackbar(key); }, 1300)
            return
        }
    }
    const changeTroop = (e, value) => {
        if (value === null) {
            formik.setValues({ ...formik.values, "troop": "" });

        } else {

            formik.setValues({ ...formik.values, "troop": `${value?.TroopName},${value?.TroopGroupName || ""}` || "" })
        }
    }

    return (
        <Slide timeout={{ enter: 1000, exit: 1000 }} direction='up' in={true} style={{ transformOrigin: '0 0 0', borderTopRightRadius: '20px', borderTopLeftRadius: '20px', background: '#ffffff' }}>
            <Container>
                {tribeOption.loading || schools.loading || troopGroup.loading ? <CustomBusy text={t("loading")} />
                    : <form onSubmit={formik.handleSubmit} name="childEditor">
                        <VBox className={`${classes.padding1} ${classes.pageStyle}`} sx={{ textAlign: "start !important" }}>
                            <Box marginBottom={"1.25rem"}>
                                <VBox className={classes.switchAndName}>
                                    <Typography className={classes.pupilName}> {`${t("myDetails")}: ${child?.Pupil_FirstName} ${child?.Pupil_lastName}`}</Typography>
                                    <HBox alignItems='center'>
                                        <Typography className={classes.pupilName}> {t("statusText")}</Typography>
                                        <Tooltip title={t("pupilStatus")}>
                                            <GreenSwitch
                                                id="isActive"
                                                name="isActive"
                                                disabled={updateStatmentApi.loading}
                                                onChange={formik.handleChange}
                                                checked={formik.values.isActive || false}
                                                value={formik.values.isActive}
                                                checkedIcon={<CheckCircleIcon />}
                                                icon={<RemoveCircleIcon sx={{ color: "#797979" }} />} />
                                        </Tooltip>
                                    </HBox>
                                </VBox>
                            </Box>
                            <CangeTribeDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} fromTribe={fromTribe} childReqText={childReqText} />
                            <VBox>
                                <HBox alignItems='center' justifyContent='space-between'>
                                    <Autocomplete
                                        width="100%"
                                        id="tribe"
                                        name="tribe"
                                        disabled={disableToChange || updateStatmentApi.loading}
                                        disableClearable={true}
                                        onBlur={formik.handleBlur}
                                        className={`${classes.marginBottom1} ${classes.width75}`}
                                        isOptionEqualToValue={(option, value) => option.Code === value.Code}
                                        noOptionsText={t("tribeNoOption")}
                                        options={tribesList}
                                        defaultValue={tribesList.find(tribe => tribe.Code === child?.DflWhs)}
                                        getOptionLabel={(option) => getTribeLabel(option)}
                                        onChange={(e, value) => formik.setValues({ ...formik.values, "tribe": value.Code })}
                                        value={tribesList.find(tribe => tribe.Code === formik.values.tribe) || null}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={option.Code}>
                                                <div style={{ color: "#3a7735" }}>{option.Name}</div>,<div style={{ fontWeight: "bold", fontSize: "0.9rem", marginRight: "3px" }}>{option.Street}</div><div style={{ fontSize: "0.9rem", marginRight: "3px" }}>{option.City}</div>
                                            </Box>
                                        )}
                                        renderInput={(params) =>
                                            <TextField {...params} label={t("tribe")}
                                                placeholder={t("tribeSearch")}
                                                id="tribe"
                                                name="tribe"
                                                error={formik.touched.tribe && Boolean(formik.errors.tribe)}
                                                helperText={formik.touched.tribe && formik.errors.tribe} />}
                                    />
                                    <Typography className={classes.underLineText} onClick={e => changeTribe()} >{t("changeTribe")}</Typography>
                                </HBox>
                                <TextField
                                    className={`${classes.marginBottom1} ${classes.RemoveTextFieldNumberArrow}`}
                                    id="firstName"
                                    name="firstName"
                                    label={t("studentFirstName")}
                                    disabled={updateStatmentApi.loading}
                                    value={formik.values.firstName}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    variant="outlined"
                                />
                                <TextField
                                    className={classes.marginBottom1}
                                    id="lastName"
                                    name="lastName"
                                    label={t("studentLastName")}
                                    disabled={updateStatmentApi.loading}
                                    value={formik.values.lastName}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    variant="outlined"
                                />
                                <ButtonGroup disabled={updateStatmentApi.loading} color="primary" aria-label="outlined primary button group" className={`${classes.IdentificationBtn} ${classes.marginBottom1}`} >
                                    <Button className={`${formik.values.idType === "1" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, "idType": "1" })}>{t("israelId")}</Button>
                                    <Button className={`${formik.values.idType === "2" ? classes.selectedBtn : classes.IdnBtn} ${classes.width6}`} onClick={e => formik.setValues({ ...formik.values, "idType": "2" })}>{t("passport")} </Button>
                                    <Button className={`${formik.values.idType === "3" ? classes.selectedBtn : classes.IdnBtn} ${classes.width8}`} onClick={e => formik.setValues({ ...formik.values, "idType": "3" })}>{t("journeyId")}</Button>
                                </ButtonGroup>
                                <TextField
                                    className={`${classes.marginBottom1} ${classes.RemoveTextFieldNumberArrow}`}
                                    type="text"
                                    id="id"
                                    name="id"
                                    disabled={updateStatmentApi.loading}
                                    value={formik.values.id}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.id && Boolean(formik.errors.id)}
                                    helperText={formik.touched.id && formik.errors.id}
                                    variant="outlined"
                                    InputProps={{
                                        inputRef: inputRef,
                                        inputProps: {
                                            inputMode: 'tel',
                                            pattern: "[0-9]*"
                                        }
                                    }}
                                    onWheel={(e) => {
                                        if (inputRef && inputRef.current && inputRef.current.blur) {
                                            inputRef.current.blur();
                                        }

                                    }}
                                />
                                <Typography variant="caption" alignSelf="flex-start">{t("studentBirthday")} </Typography>
                            </VBox>
                            <FormControl >
                                <HBox className={`${classes.marginBottom1} ${classes.birthdayInput}`}>
                                    {/* the values attribute getting an array */}
                                    <ReactCodeInput autoComplete={false} type='number' inputMode="numeric" onComplete={isValidDay} autoFocus={false} onChange={onBirthdayDayChange} values={[birthday.day.charAt(0), birthday.day.charAt(1)]} fields={2}
                                        className={classes.datePinInputStyle} ref={dayRef} />
                                    <Typography align="center" component="h2" variant="h6">
                                        /
                                    </Typography>
                                    <ReactCodeInput autoComplete={false} type='number' inputMode="numeric" onComplete={isValidMonth} autoFocus={false} onChange={onBirthdayMonthChange} values={[birthday.month.charAt(0), birthday.month.charAt(1)]} fields={2}
                                        className={classes.datePinInputStyle} ref={monthRef} />
                                    <Typography align="center" component="h2" variant="h6">
                                        /
                                    </Typography>
                                    <ReactCodeInput autoComplete={false} type='number' inputMode="numeric" onComplete={isValidYear} autoFocus={false} onChange={onBirthdayYearChange} values={[birthday.year.charAt(0), birthday.year.charAt(1), birthday.year.charAt(2), birthday.year.charAt(3)]} fields={4}
                                        className={classes.datePinInputStyle} ref={yearRef} />
                                </HBox>
                                <FormHelperText error={true} className={birthdayError ? classes.marginBottom1 : ''}>{birthdayError ? t("validDate") : ''}</FormHelperText>
                            </FormControl>
                            <Autocomplete
                                id="troop"
                                name="troop"
                                disabled={updateStatmentApi.loading}
                                onBlur={formik.handleBlur}
                                className={classes.marginBottom1}
                                isOptionEqualToValue={(option, value) => option.TroopGroupCode === value.TroopGroupCode}
                                noOptionsText={t("troopNoOption")}
                                options={troopList}
                                defaultValue={troopList.find(troop => `${troop.TroopName},${troop.TroopGroupName}` === `${child?.Troops},${child?.Group}`)}
                                getOptionLabel={(option) => { return (`${option.TroopName},${option.TroopGroupName || ""}`) }}
                                onChange={(e, value) => changeTroop(e, value)}
                                value={troopList.find(troop => `${troop.TroopName},${troop.TroopGroupName || ""}` === formik.values.troop) || null}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.ID}>
                                        <div style={{ fontWeight: "bold", fontSize: "0.9rem" }}>{option.TroopName}</div>,<div style={{ color: "#3a7735", marginRight: "3px" }}>{option.TroopGroupName || ""}</div>
                                    </Box>
                                )}
                                renderInput={(params) =>
                                    <TextField {...params} label={t("troopGroup")}
                                        placeholder={t("troopSearch")}
                                        id="troop"
                                        name="troop"
                                        error={formik.touched.troop && Boolean(formik.errors.troop)}
                                        helperText={formik.touched.troop && formik.errors.troop} />}
                            />
                            <HBox width={'100%'} className={` ${classes.marginBottom1}`}>
                                <TextField
                                    style={{ width: "100%" }}
                                    disabled={updateStatmentApi.loading}
                                    className={`${classes.marginBottom1} ${classes.RemoveTextFieldNumberArrow}`}
                                    label={t("myPhone")}
                                    InputProps={{
                                        inputRef: inputRef1,
                                        inputProps: {
                                            inputMode: 'tel',
                                            pattern: "[0-9]*"
                                        }
                                    }}
                                    onWheel={(e) => {
                                        if (inputRef1 && inputRef1.current && inputRef1.current.blur) {
                                            inputRef1.current.blur();
                                        }
                                    }}
                                    type="tel"
                                    id="childPhone"
                                    name="childPhone"
                                    value={formik.values.childPhone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.childPhone && Boolean(formik.errors.childPhone)}
                                    helperText={formik.touched.childPhone && formik.errors.childPhone}
                                    variant="outlined"
                                />
                            </HBox>
                            <FormControl fullWidth>
                                <InputLabel>{t("class")}</InputLabel>
                                <Select
                                    className={classes.marginBottom1}
                                    id="class"
                                    name="class"
                                    label={t("class")}
                                    value={formik.values.class}
                                    onBlur={classBlur}
                                    onChange={formik.handleChange}
                                    onClose={() => {
                                        setTimeout(() => {
                                            document.activeElement.blur();
                                        }, 0);
                                    }}
                                    disabled={updateStatmentApi.loading}
                                >
                                    {Object.values(permanentInfo.childClasses).map((childClass, i) => {
                                        return (
                                            <MenuItem value={childClass} key={i}>{childClass}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <FormHelperText error={formik.touched.class && Boolean(formik.errors.class)} >{formik.touched.class && formik.errors.class}</FormHelperText>
                            </FormControl>
                            {!!schoolsList && <Autocomplete
                                id="school"
                                name="school"
                                disabled={updateStatmentApi.loading}
                                disableClearable={true}
                                className={classes.marginBottom1}
                                options={schoolsList.sort((a, b) => b.CityName.toString().localeCompare(a.CityName.toString()))}
                                defaultValue={schoolsList.find(school => school.SchoolCode === child?.School)}
                                isOptionEqualToValue={(option, value) => option.SchoolCode === value.SchoolCode}
                                onBlur={formik.handleBlur}
                                onChange={(e, value) => valueChanged(value, "school")}
                                inputValue={schoolValue}
                                onInputChange={(event, newInputValue) => { setSchoolValue(newInputValue); }}
                                noOptionsText={t("schoolNoOption")}
                                getOptionLabel={(option) => `${option.SchoolName} , ${option.CityName}`}
                                renderOption={(props, option, state, i) => (
                                    <Box component="li" {...props} key={`${option.SchoolCode}-${option.CityCode}`}>
                                        <div style={{ color: "#3a7735" }}>{option.SchoolName}</div>,<div style={{ fontWeight: "bold", fontSize: "0.9rem" }}>{option.CityName}</div>
                                    </Box>
                                )}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        placeholder={t("searchPlaceHolder1")}
                                        label={t("schoolSearch")}
                                        error={formik.touched.school && Boolean(formik.errors.school)}
                                        helperText={formik.touched.school && formik.errors.school}
                                        InputProps={{
                                            inputMode: "search",
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {schools.loading && <CircularProgress color="inherit" size={20} />}
                                                    {params.InputProps.endAdornment}
                                                </>)
                                        }} />}
                            />}
                            <FormControl error={formik.touched.gender && Boolean(formik.errors.gender)}>
                                <InputLabel>{t("gender")}</InputLabel>
                                <Select
                                    className={classes.marginBottom1}
                                    id="gender"
                                    name="gender"
                                    label={t("gender")}
                                    disabled={updateStatmentApi.loading}
                                    value={formik.values.gender}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                >
                                    {Object.values(permanentInfo.childGender).map((gender, i) => {
                                        return (
                                            <MenuItem value={gender} key={i} >{gender}</MenuItem>
                                        )
                                    })}
                                </Select>
                                {formik.touched.gender && Boolean(formik.errors.gender) && <FormHelperText>{formik.touched.class && formik.errors.gender}</FormHelperText>}
                            </FormControl>
                            <HBox width={'100%'} >
                                <Typography variant="caption" alignSelf="flex-start">{t("genderAppeal1")} </Typography>
                                <Tooltip title={t("genderAppealText")}>
                                    <InfoOutlinedIcon sx={{ color: "#000000", fontSize: '1rem', marginLeft: "4px" }} />
                                </Tooltip>
                            </HBox>
                            <TextField
                                className={classes.marginBottom1}
                                id="genderAppeal"
                                name="genderAppeal"
                                label={t("genderAppeal")}
                                disabled={updateStatmentApi.loading}
                                value={formik.values.genderAppeal}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.genderAppeal && Boolean(formik.errors.genderAppeal)}
                                helperText={formik.touched.genderAppeal && formik.errors.genderAppeal}
                                variant={"outlined"}
                            />
                            <HBox justifyContent="center">
                                {updateStatmentApi.loading ? <CircularProgress color="primary" /> :
                                    <Button className={classes.buttonGreen} type="submit" endIcon={<ArrowBackIosNewOutlinedIcon />} >
                                        {t("update")}
                                    </Button>}
                            </HBox>
                        </VBox >
                    </form>}
            </Container>
        </Slide >
    );
}

export const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiButtonBase-root.MuiSwitch-switchBase': {
        height: '100%'
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.green.main,
        backgroundColor: "transparent",
        '&:hover': {
            backgroundColor: alpha("#3a7735", theme.palette.action.hoverOpacity),
        },
    },
}));

const CangeTribeDialog = ({ dialogOpen, setDialogOpen, fromTribe, childReqText }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const moneyLink = "https://zofim-service.consist.co.il/servicePortal/submitIncident?category_id=309";
    const supportLink = "https://zofim-service.consist.co.il/servicePortal/submitIncident?category_id=716";

    const parentMoveToContact = () => {
        setDialogOpen(false);
        navigate('/contact');
    }

    return (
        <GenericDialog open={dialogOpen} setOpen={setDialogOpen} iconSVG={childError} showRopes={true} height={"24rem"}>
            {fromTribe ? <>
                <Typography color="#064601" mt="1rem" variant="mediumBoldText" sx={{ textAlign: "center" }}>{t('ohNeedTheZofim')}</Typography>
                <Typography sx={{ textAlign: "center" }}>{t('ohNeedTheZofim1')}</Typography>
                <Typography sx={{ textAlign: "center" }}>{t('ohNeedTheZofim2')}</Typography>
                <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center' justifyContent='center' sx={{ marginTop: "1rem" }}>
                    <Button className={classes.buttonGreen} onClick={e => window.open(moneyLink)} sx={{ margin: "1rem" }}>{t("moneyNote")}</Button>
                    {/* <Button className={classes.buttonGreen} onClick={e => window.open(supportLink)} sx={{ margin: "1rem" }}>{t("supportNote")}</Button> */}
                </Box>
            </> : <>
                {childReqText ? <Typography color="#064601" mt="0.5rem" variant="mediumBoldText" sx={{ textAlign: "center", padding: "2rem" }}>{t('childHaveReqsError')}</Typography> : <>
                    <Typography color="#064601" mt="1rem" variant="mediumBoldText" sx={{ textAlign: "center" }}>{t('ohNeedTheTribe')}</Typography>
                    <Button className={classes.buttonGreen} onClick={e => parentMoveToContact()} sx={{ marginTop: "2rem" }}>{t("contactTribe")}</Button></>}</>}
        </GenericDialog>
    )
}